/* eslint-disable no-alert */
(function() {
    'use strict';

    vf.glnav = {
        settings: {
            target: '.mod-glnav',
            isInMobileOrTabletViewPort: null,
            loginDataVO: null,
            mobilmenuOpendInOrderToShowMyVfFlag: false,
            showFlyoutAgianFlag: false
        },
        /**
         * init function
         */
        init: function(context) {
            // init settings
            var s = vf.glnav.settings;
            vf.glnav.settings.isInMobileOrTabletViewPort = vf.glnav.isTabletOrMobile();
            vf.LOGIN_STATES_CONST = {
                STANDARD_LOGIN: 'standard-login',
                STANDARD_LOGOUT: 'standard-logout',
                SEAMLESS_LOGIN: 'seamless-login',
                SEAMLESS_AVAILABE: 'seamless-available'
            };

            vf.loginState = '';
            vf.glnav.setLoginDataVO(null);

            var supportsTouch = 'ontouchstart' in window || navigator.msMaxTouchPoints;

            window.onload = function(){
                var chatbotElm = document.getElementById('chatbot');
                if (chatbotElm) {
                    chatbotElm.onclick = function () {
                        document.getElementById('chatbotBtn').click();
                        document.getElementsByClassName('icon icon-burger')[0].click();
                    };
                }

                var ticketsElm = document.getElementById('tickets');
                if (ticketsElm) {
                    ticketsElm.onclick = function () {
                        document.getElementById('ticketsBtn').click();
                        document.getElementsByClassName('icon icon-burger')[0].click();
                    };
                }


            }();

            $(s.target, context).addBack(s.target).each(function() {
                // legacy sicky pricebox patc
                var mainNavTop = $(s.target).find('.main-nav').offset().top;
                var mddPositionFixed = false;
                // sticky header
                vf.glnav.hasLegcySticky();
                $(window).on('scroll', function() {
                    var topBanner = $('.vo-ws2-promoteaser');
                    if (!topBanner.length) {
                        topBanner = $('#non-blocking-consent-notification');
                    }

                    if (topBanner.length && !mddPositionFixed) {
                        mainNavTop = $(s.target).find('.main-nav').offset().top;
                        mddPositionFixed = true;
                    }
                    if ($(window).scrollTop() > mainNavTop && !vf.glnav.hasLegcySticky()) {
                        if ((!1 === $('.ebpaBody').length) && (0 < (document.location.href.indexOf('/proxy42')) || 0 < (document.location.href.indexOf('/contact')))) {
                            $(s.target).find('.main-nav').css({ position: 'fixed', top: '0px', left: '0px', width: '1230px' });
                        } else {
                            $(s.target).find('.main-nav').css({ position: 'fixed', top: '0px' });
                        }
                        $(s.target).find('ul.list-nav-main').removeClass('scrollweg');
                    } else {
                        $(s.target).find('.main-nav').css({ position: 'relative', top: '0px' });

                        if (0 !== $(window).scrollTop() && vf.glnav.hasLegcySticky()) {
                            $(s.target).find('ul.list-nav-main').addClass('scrollweg');
                        }
                    }
                });

                // custom handlers for myVodafone button
                $(s.target).find('.open-overlay-my-vf').on('mouseenter', function(event) {
                    event.preventDefault();
                    if (vf.glnav.getLoginDataVO() && !supportsTouch) {
                        $(s.target).find('.myvf-flyout').addClass('nav-display');
                    }
                });
                $(s.target).find('.main-nav .list-nav-right').on('mouseleave', function(event) {
                    if (vf.glnav.getLoginDataVO()) {
                        $(s.target).find('.myvf-flyout').removeClass('nav-display');
                    }
                });
                $(s.target).find('.open-overlay-my-vf').on('click', function(event) {
                    event.preventDefault();
                    if (!vf.glnav.getLoginDataVO() || (vf.glnav.getLoginDataVO() && supportsTouch)) {
                        vf.glnav.myVodafoneButtonHandler();
                    }
                });

                // menu button click
                $(s.target).find('.item-menu ').on('click', function() {
                    $(this).parent().parent().parent().toggleClass('nav-display');
                    //$('.glnav-tab-wrapper .btn').addClass('btn-sml'); if menu visible
                    if ($(this).parent().parent().parent().hasClass('nav-display')) {
                        $(this).parent().parent().parent().find('ul.list-nav-main').css('-webkit-overflow-scrolling', 'touch');
                        // add bg
                        $('.mod-glnav .main-nav.nav-display .belt').prepend('<div class="list-bg" id="glnavListBg"></div>');

                        $('.mod-glnav .myvf-flyout').removeClass('nav-display');
                    } else {
                        $(this).parent().parent().parent().find('ul.list-nav-main').css('-webkit-overflow-scrolling', 'auto');
                        // remove div
                        $('#glnavListBg').remove();
                    }
                    vf.glnav.setMenuBgHeight();
                });

                // meta-nav

                $(s.target).find('.list-nav-meta .meta-sub-trigger').on('mouseenter', function(event) {
                    $(this).addClass('nav-display');
                });
                $(s.target).find('.list-nav-meta .meta-sub-trigger').on('mouseleave', function(event) {
                    $(this).removeClass('nav-display');
                });

                // main buttons (kontakt, search, help, shopping basket, login)
                $(s.target).find('.nav-item-main-right').on('click', function(event) {
                    $('.glnav-tab-wrapper .mod-tabs ul.mod-tabs-nav li:first-child').addClass('active');

                    $('.glnav-tab-wrapper .mod-tabs #registrierung,#quicklinks,#info-app,#contact').addClass('active');
                    $('.glnav-tab-wrapper .mod-tabs #registrierung,#quicklinks,#info-app,#contact').css('display', 'none');

                    $('.glnav-tab-wrapper .mod-tabs #myvf,#hotline').addClass('active');
                    $('.glnav-tab-wrapper .mod-tabs #myvf,#hotline').css('display', 'block');
                    // set the focus in case of the search button
                    if ('searchOverlay' === $(this).find('.open-overlay').attr('data-overlayid')) {
                        $('.mod-overlay.glnav #search-q').trigger('focus');
                    }
                    $(window).trigger('resize');

                    if ($(event.currentTarget).hasClass('item-contact')) {
                        var phoneNumber = $('.glnav .glnav-tab-wrapper #hotline .btn:first').attr('href');
                        var numberHtml = $('.glnav .glnav-tab-wrapper #hotline .btn:first').html();
                        var markupString = '<a href="' + phoneNumber + '" class="btn">' + numberHtml + '</a>';
                        $('.glnav #hotline .btn:first').remove();
                        $('.glnav .glnav-tab-wrapper #hotline .text-only:first').after(markupString);
                        // TODO: this has to be removed
                    }
                });

                // adversitement
                $('.glnav-tab-wrapper .mod-tabs ul.mod-tabs-nav li').on('click', function() {
                    if ('#quicklinks' === $(this).find('a').attr('href')) {
                        vf.glnav.ddqPush('quicklinks');
                    }
                });


                // reset tabs if overlay closes
                $(window).on('vf::overlayclosed', function() {
                    $('.mod-overlay.glnav .mod-tabs-nav li').removeClass('active');
                    $('.mod-overlay.glnav .search-box .se-input').val('');
                    $('.mod-overlay.glnav #mdd-login-form #input-username').val('');
                    $('.mod-overlay.glnav #mdd-login-form #input-password').val('');

                    // close the menu if it was opend from the mobile myVF overlay
                    if (vf.glnav.settings.mobilmenuOpendInOrderToShowMyVfFlag) {
                        $('.mod-glnav .main-nav').removeClass('nav-display');
                        vf.glnav.settings.mobilmenuOpendInOrderToShowMyVfFlag = false;
                    } else {
                        $('.mod-glnav .main-nav ul.list-nav-main').css('-webkit-overflow-scrolling', 'touch');
                    }

                    //Show flyout when myvf overlay is closed
                    if (vf.glnav.settings.showFlyoutAgianFlag) {
                        $('.chatFlyout').show();
                    }

                    //Show pricebox when myvf overlay is closed
                    $('#nsf-pricebox').show();
                });

                // event lisnter for userdata to show user-name in MDD
                document.addEventListener('userDataFired', function(e){
                    $('.mod-glnav .myvf-flyout .h5').html('Du bist eingeloggt als' + '<div class="user-name">' + e.detail.userName + '</div>' );
                });

                // window reseize event
                $(window).on('resize', function(event) {
                    vf.glnav.resizeHandler();
                });

                // search all button
                $('.mod-overlay.glnav .search-all-button').on('click', function(event) {
                    event.preventDefault();
                    $('.mod-overlay.glnav .search-box').submit();
                });

                // update the login indicator in single page apps
                document.addEventListener('login-status-change', function(e) {
                    vf.glnav.updateLoginIndicator();
                }, false);

                // set up event handlers
                // 1st level
                $(s.target).find('.nav-item-main > .chevron').on('click', function(event) {
                    vf.glnav.interactionHandler(this, false);
                });

                if (!supportsTouch) {
                    // desktop no touch
                    $(s.target).find('.nav-item-main').on('mouseenter', function(event) {
                        vf.glnav.interactionHandler(this, false, 'show');
                    });
                    $(s.target).find('.nav-item-main').on('mouseleave', function(event) {
                        vf.glnav.interactionHandler(this, false, 'hide');
                    });

                } else {
                    // touch device
                    $(s.target).find('.nav-item-main').on('click', function(event) {
                        // large touch
                        if (!vf.glnav.isTabletOrMobile()) {


                            if (!$(this).find('.nav-secondary').hasClass('nav-display')) {
                                event.preventDefault();

                                $(s.target).find('.nav-secondary').removeClass('nav-display');
                                $(this).find('.nav-secondary').addClass('nav-display');

                                $(s.target).find('.nav-item-main').removeClass('item-main-active');
                                $(this).addClass('item-main-active');
                            }
                        }
                    });
                    if (!vf.glnav.isTabletOrMobile()) {
                        // close menu for large touch device
                        $('body').on('click', function(event) {
                            $(s.target).find('.nav-secondary, .nav-secondary-sub').removeClass('nav-display');
                            $(s.target).find('.nav-item-main').removeClass('item-main-active');
                            $(s.target).find('.nav-item-sec').removeClass('item-sub-active');
                        });
                        $(s.target).on('click', function(event) {
                            event.stopPropagation();
                        });
                        $(window).on('scroll', function() {
                            $(s.target).find('.nav-secondary, .nav-secondary-sub').removeClass('nav-display');
                            $(s.target).find('.nav-item-main').removeClass('item-main-active');
                            $(s.target).find('.nav-item-sec').removeClass('item-sub-active');
                        });
                    }
                }

                // 2nd level
                $('.nav-item-sec > .chevron').on('click', function(event) {
                    vf.glnav.interactionHandler(this, true);
                });

                if (!supportsTouch) {
                    $(s.target).find('.nav-item-sec').on('mouseenter', function(event) {
                        vf.glnav.interactionHandler(this, true, 'show');
                    });
                    $(s.target).find('.nav-item-sec').on('mouseleave', function(event) {
                        vf.glnav.interactionHandler(this, true, 'hide');
                    });
                } else {
                    $(s.target).find('.nav-item-sec').on('click', function(event) {
                        var numSubItems = $(this).find('.list-sec-sub li').length;
                        if (!vf.glnav.isTabletOrMobile()) {
                            if (!$(this).find('.nav-secondary-sub').hasClass('nav-display') && 0 < numSubItems) {
                                event.preventDefault();
                                $(s.target).find('.nav-secondary-sub').removeClass('nav-display');
                                $(this).find('.nav-secondary-sub').addClass('nav-display');
                                $(s.target).find('.nav-item-sec').removeClass('item-sub-active');
                                $(this).toggleClass('item-sub-active');
                            }
                        }

                    });
                }

                // document ready
                $(function() {
                    // read the login cookie
                    vf.glnav.updateLoginIndicator();
                    // in case of enterprise
                    var mode = (undefined === $(s.target).parents('.enterprise').get(0)) ? 'consumer' : 'enterprise';
                    if ('enterprise' === mode) {
                        vf.glnav.setShoppingChartItemCounter(window.getEntCartCount());
                    } else {
                        // set default shopping chart counter
                        vf.glnav.setShoppingChartItemCounter(window.getCartCount());
                    }

                    var myOffset = $('.mod-glnav .main-nav').height() + 10;

                    if (location.hash) {
                        try {
                            var offsetA = $(location.hash).offset();
                            var scrolltoA = offsetA.top - myOffset;
                            $('html, body').stop().animate({ scrollTop: scrolltoA }, 'slow');
                        } catch (e) {}
                    }

                    var anchors = $.find('a[href^=\'#\']');
                    $.each(anchors, function(index, element) {
                        var anchorId = $(element).attr('href');
                        // Only register click event when href contains a real anchor and said anchor is not within an overlay or tabs
                        if (anchorId.substring(1).length && $(anchorId).length && 0 === $(element).parents('.overlay-content').length && 0 === $(element).parents('.mod-tabs').length && 0 === $(element).parents('.divTabbedNavigationDynHUB').length) {
                            $(element).on('click', function(event) {
                                event.stopPropagation();
                                event.preventDefault();
                                var offset = $(anchorId).offset();
                                var scrollto = offset.top - myOffset;
                                $('html, body').stop().animate({ scrollTop: scrollto }, 'slow');
                            });
                        }
                    });
                });
            });
            vf.glnav.getSeamlessLoginNotifficationStatus();
        },
        sha256: function(ascii) {
            function rightRotate(value, amount) {

                return (value >>> amount) | (value << (32 - amount));
            }
            var mathPow = Math.pow;
            var maxWord = mathPow(2, 32);
            var lengthProperty = 'length';
            var i;
            var j; // Used as a counter across the whole file
            var result = '';

            var words = [];
            var asciiBitLength = ascii[lengthProperty] * 8;

            // * caching results is optional - remove/add slash from front of this line to toggle
            // Initial hash value: first 32 bits of the fractional parts of the square roots of the first 8 primes
            // (we actually calculate the first 64, but extra values are just ignored)
            var hash = this.sha256.h = this.sha256.h || [];
            // Round constants: first 32 bits of the fractional parts of the cube roots of the first 64 primes
            var k = this.sha256.k = this.sha256.k || [];
            var primeCounter = k[lengthProperty];
            /*/
            let hash = [], k = [];
            let primeCounter = 0;
            //*/

            var isComposite = {};
            for (var candidate = 2; 64 > primeCounter; candidate++) {
                if (!isComposite[candidate]) {
                    for (i = 0; 313 > i; i += candidate) {
                        isComposite[i] = candidate;
                    }
                    hash[primeCounter] = (mathPow(candidate, .5) * maxWord) | 0;
                    k[primeCounter++] = (mathPow(candidate, 1 / 3) * maxWord) | 0;
                }
            }

            ascii += '\x80'; // Append Ƈ' bit (plus zero padding)
            while (ascii[lengthProperty] % 64 - 56) {
                ascii += '\x00'; // More zero padding
            }
            for (i = 0; i < ascii[lengthProperty]; i++) {
                j = ascii.charCodeAt(i);
                if (j >> 8) {
                    return; // ASCII check: only accept characters in range 0-255
                }
                words[i >> 2] |= j << ((3 - i) % 4) * 8;
            }
            words[words[lengthProperty]] = ((asciiBitLength / maxWord) | 0);
            words[words[lengthProperty]] = (asciiBitLength);

            // process each chunk
            for (j = 0; j < words[lengthProperty];) {
                var w = words.slice(j, j += 16); // The message is expanded into 64 words as part of the iteration
                var oldHash = hash;
                // This is now the undefinedworking hash", often labelled as variables a...g
                // (we have to truncate as well, otherwise extra entries at the end accumulate
                hash = hash.slice(0, 8);

                for (i = 0; 64 > i; i++) {
                    var i2 = i + j;
                    // Expand the message into 64 words
                    // Used below if
                    var w15 = w[i - 15];
                    var w2 = w[i - 2];

                    // Iterate
                    var a = hash[0];
                    var e = hash[4];
                    var temp1 = hash[7] +
                        (rightRotate(e, 6) ^ rightRotate(e, 11) ^ rightRotate(e, 25)) // S1
                        +
                        ((e & hash[5]) ^ ((~e) & hash[6])) // ch
                        +
                        k[i]
                        // Expand the message schedule if needed
                        +
                        (w[i] = (16 > i) ? w[i] : (
                            w[i - 16] +
                            (rightRotate(w15, 7) ^ rightRotate(w15, 18) ^ (w15 >>> 3)) // s0
                            +
                            w[i - 7] +
                            (rightRotate(w2, 17) ^ rightRotate(w2, 19) ^ (w2 >>> 10)) // s1
                        ) | 0);
                    // This is only used once, so *could* be moved below, but it only saves 4 bytes and makes things unreadble
                    var temp2 = (rightRotate(a, 2) ^ rightRotate(a, 13) ^ rightRotate(a, 22)) // S0
                        +
                        ((a & hash[1]) ^ (a & hash[2]) ^ (hash[1] & hash[2])); // maj

                    hash = [(temp1 + temp2) | 0].concat(hash); // We don't bother trimming off the extra ones, they're harmless as long as we're truncating when we do the slice()
                    hash[4] = (hash[4] + temp1) | 0;
                }

                for (i = 0; 8 > i; i++) {
                    hash[i] = (hash[i] + oldHash[i]) | 0;
                }
            }

            for (i = 0; 8 > i; i++) {
                for (j = 3; j + 1; j--) {
                    var b = (hash[i] >> (j * 8)) & 255;
                    result += ((16 > b) ? 0 : '') + b.toString(16);
                }
            }

            // eslint-disable-next-line consistent-return
            return result;
        },
        getSeamlessLoginNotifficationStatus: function() {
            $('#seamlessLoginButNoApproval').css('display', 'none');
            $('#seamlessLoginwithApproval').css('display', 'none');
            $('#approvalWithWifi').css('display', 'none');
            $('#seamlessLoginwithApprovalBtn').on('click', function() {
                window.location.href = '/meinvodafone/account/login';

                // $.ajax({
                //     type: 'POST',
                //     url: '/mint/rest/session/start',
                //     dataType: 'json',
                //     contentType: 'application/json',
                //     data: JSON.stringify({
                //         authMethod: 'AAA',
                //         byPIN: false,
                //             additionalParameters: {
                //                 deviceType: 'Smartphone'
                //             }
                //         }),
                //     success: function () {
                //         window.location.href = '/meinvodafone/services';

                //     }
                // });
            });

            var vfctCookie = $.cookie('vfct');
            var meinVodafoneSeamlessApprovalCookie = $.cookie('meinVodafoneSeamlessApproval');
            if (vfctCookie && !meinVodafoneSeamlessApprovalCookie) {
                $('#seamlessLoginButNoApproval').css('display', 'block');
            } else if (!vfctCookie && meinVodafoneSeamlessApprovalCookie) {
                $('#approvalWithWifi').css('display', 'block');
            } else if ('mobile' === $.cookie('vfct')) {
                $('#seamlessLoginwithApproval').css('display', 'block');
            }


            // else {
            //     console.log('seamless-login');
            //     // check for (ST/local) env  => not to execute ajax
            //     // (ST/local) + MDD cookie => execute ajax
            //     // else production => execute ajax
            //     var origin = window.location.origin;
            //     var seamlessMddCookie = $.cookie('seamlessMDD');
            //     var environmentCookie = $.cookie('environment');
            //     var SeamlessLoginMsisdn = window.localStorage.getItem('SeamlessLogin');
            //     if ('dev' === environmentCookie && seamlessMddCookie ) {
            //         var respose = {
            //             msisdn: SeamlessLoginMsisdn,
            //             userId: 19368091,
            //             authLevel: 'AAA'
            //         };

            //         var activeMsisdn = respose.msisdn;
            //         if (vf.glnav.sha256(activeMsisdn) === meinVodafoneSeamlessApprovalCookie ){
            //             $('#seamlessLoginwithApproval').css('display', 'block');

            //         } else {
            //             $('#seamlessLoginButNoApproval').css('display', 'block');
            //             $.cookie('meinVodafoneSeamlessApproval', null, { path: '/' });
            //         }

            //     } else if ( 'mobile' === $.cookie('vfct') ) {
            //         $.ajax({
            //             type: 'POST',
            //             url: '/mint/rest/session/start',
            //             dataType: 'json',
            //             contentType: 'application/json',
            //             data: JSON.stringify({
            //                 authMethod: 'AAA',
            //                 byPIN: false,
            //                     additionalParameters: {
            //                         deviceType: 'Smartphone'
            //                     }
            //                 }),
            //             success: function (resp) {
            //                 var activeMsisdn = resp.msisdn;
            //                 if (vf.glnav.sha256(activeMsisdn) === meinVodafoneSeamlessApprovalCookie ){
            //                     $('#seamlessLoginwithApproval').css('display', 'block');

            //                 } else {
            //                     $('#seamlessLoginButNoApproval').css('display', 'block');
            //                     $.cookie('meinVodafoneSeamlessApproval', null, { path: '/' });
            //                 }
            //                 $.get(
            //                     '/mint/rest/session/end',
            //                     {},
            //                     function() {
            //                     }
            //                 );

            //             }
            //         });
            //     }

            // }

        },
        /**
         * set menu bg height
         */
        setMenuBgHeight: function() {
            var minHeight = window.innerHeight + 120;
            //var menuHeight = $('.mod-glnav .main-nav ul.list-nav-main')[0].scrollHeight + 50;
            //var myHeight = Math.max(minHeight, menuHeight) + 'px';
            $('#glnavListBg').css('height', minHeight);
        },

        /**
         * in case there is a sticky price box - set the margin-top of the body
         * return {bool} either true or false.
         */
        hasLegcySticky: function() {
            //&& 0 < $('.wrapper.noMDD').length
            var pageWithLegacyPriceBox = 0 < $('.stickyBox').length && 768 > screen.width;
            var checkoutPage = 0 < $('.noMDD .total-costs').length;

            if (pageWithLegacyPriceBox || checkoutPage) {
                if (pageWithLegacyPriceBox && !checkoutPage) {
                    $('body').css('margin-top', '44px');
                }
                return true;
            } else {
                return false;
            }
        },


        /**
         * make sure everything is reset to avoid navigation errors
         */
        resizeHandler: function() {

            if (vf.glnav.settings.isInMobileOrTabletViewPort !== vf.glnav.isTabletOrMobile()) {
                var s = vf.glnav.settings;
                $(s.target).find('.nav-item-main').removeClass('item-main-active');
                $(s.target).find('.nav-item-main.selected').addClass('item-main-active');

                $(s.target).find('.nav-item-sec').removeClass('item-sec-active');
                $(s.target).find('.nav-item-sec.selected').addClass('item-sec-active');

                // desktop
                if (!vf.glnav.isTabletOrMobile()) {
                    $(s.target).find('.nav-item-main').find('.nav-secondary').animate({ height: 'show' }, 1);
                    $(s.target).find('.nav-item-sec').find('.nav-secondary-sub').animate({ height: 'show' }, 1);

                    $(s.target).find('.nav-item-main').find('.nav-secondary').removeClass('nav-display');
                    $(s.target).find('.nav-item-sec').find('.nav-secondary-sub').removeClass('nav-display');

                } // tablet/mobile
                else {
                    $('.nav-item-main:not(.selected)').find('.nav-secondary').animate({ height: 'hide' }, 1);
                    $('.nav-item-sec:not(.selected)').find('.nav-secondary-sub').animate({ height: 'hide' }, 1);
                }
                vf.glnav.settings.isInMobileOrTabletViewPort = vf.glnav.isTabletOrMobile();
            }
        },



        /**
         * this function is triggered on mouse enter (desktop) or click (touch device)
         * @param {string} targetRef - the string reference to the link element (either the link or the arrow).
         * @param {string} fromSecondLevel - defines if the event comes from first or second level
         * @param {string} mode - optional value either toggle(default), show ore hide         *
         */
        interactionHandler: function(targetRef, fromSecondLevel, mode) {
            if (mode === undefined) {
                mode = 'toggle';
            }
            var $myTarget;
            // click event on the arrow
            if ($(targetRef).hasClass('chevron')) {
                $myTarget = $(targetRef).parent();
            } else {
                // mouse move event
                // cancel if mobile
                if (vf.glnav.isTabletOrMobile()) { return; }
                $myTarget = $(targetRef);
            }
            if (!fromSecondLevel) {
                vf.glnav.adjustMenu($myTarget, 'item-main-active', '.nav-secondary', mode);
            } else {
                vf.glnav.adjustMenu($myTarget, 'item-sec-active', '.nav-secondary-sub', mode);
            }
            //setTimeout(vf.glnav.setMenuBgHeight(), 500);
        },


        /**
         * ultility function for above
         * @param {object} $myTarget - the jquery object to be modified
         * @param {string} selectedClassName - the class name of the mobile menu to be animated (mobile only)
         * @param {string} secNavClassName - the class name of the navigation below
         */
        adjustMenu: function($myTarget, selectedClassName, secNavClassName, mode) {

            // for desktop
            if (!vf.glnav.isTabletOrMobile()) {
                if ('toggle' === mode) {
                    $myTarget.find(secNavClassName).toggleClass('nav-display');
                    if ($myTarget.find(secNavClassName).hasClass('nav-display')) {
                        $myTarget.addClass('item-main-active');
                    } else {
                        $myTarget.removeClass('item-main-active');
                    }
                    if (0 < $myTarget.find('.list-sec-sub li').length) {
                        $myTarget.toggleClass('item-sub-active');
                    };
                } else {
                    if ('show' === mode) {
                        $myTarget.addClass('item-main-active');
                        $myTarget.find(secNavClassName).addClass('nav-display');
                        if (0 < $myTarget.find('.list-sec-sub li').length) {
                            $myTarget.addClass('item-sub-active');
                        };
                    } else {
                        $myTarget.removeClass('item-main-active');
                        $myTarget.find(secNavClassName).removeClass('nav-display');
                        if (0 < $myTarget.find('.list-sec-sub li').length) {
                            $myTarget.removeClass('item-sub-active');
                        };
                    }
                }
            }
            // mobile
            else {
                $myTarget.toggleClass(selectedClassName);
                $myTarget.find(secNavClassName).animate({ height: 'toggle' }, 500);
            }

        },

        /**
         * utility function
         * @return {bool} either true or false.
         */
        isTabletOrMobile: function() {
            if (1025 <= window.innerWidth) {
                return false;
            } else {
                return true;
            }
        },

        /**
         * myVodafone button handler
         */
        myVodafoneButtonHandler: function() {
            if (!vf.glnav.getLoginDataVO()) {
                $('.mod-glnav .main-nav ul.list-nav-main').css('-webkit-overflow-scrolling', 'auto');

                if (vf.loginState === vf.LOGIN_STATES_CONST.SEAMLESS_LOGIN) {
                    window.location.href = 'https://eweb2.vfd2-testnet.de/meinvodafone/account/login/seamless/info?loginstatus=true';
                } else {
                    if (vf.glnav.isTabletOrMobile() && !$('.mod-glnav .main-nav').hasClass('nav-display')) {
                        $('.mod-glnav .main-nav').addClass('nav-display');
                        vf.glnav.settings.mobilmenuOpendInOrderToShowMyVfFlag = true;
                    }
                    vf.overlay.openOverlayDirect('meinVodafoneOverlay');
                }
                // do some advertitment things
                vf.glnav.ddqPush('login');
                // Hide flyout when myvf overlay is open
                if (0 < $('.chatFlyout').length && 'none' !== $('.chatFlyout').css('display')) {
                    vf.glnav.settings.showFlyoutAgianFlag = true;
                } else {
                    vf.glnav.settings.showFlyoutAgianFlag = false;
                }
                $('.chatFlyout').hide();

                //Hide pricebox when myvf overlay is open
                $('#nsf-pricebox').hide();
            } else {
                $('.mod-glnav .myvf-flyout').toggleClass('nav-display');
                // hide the menu in case it is open
                $('.mod-glnav .main-nav').removeClass('nav-display');
                $('#glnavListBg').remove();
            }
        },
        /**
         * ddq push
         * @param {string} myId - either 'login' or 'quicklinks'
         */
        ddqPush: function(myId) {
            if ('undefined' !== typeof _ddq && 'function' === typeof _ddq.push) {
                if ('undefined' === typeof vf.glnav.ddqPushFlags) {
                    vf.glnav.ddqPushFlags = { login: false, quicklinks: false };
                }
                if (!vf.glnav.ddqPushFlags[myId]) {
                    var myName = 'mein vodafone login:' + myId;
                    _ddq.push(['layover', { action: 'show', name: myName }]);
                    vf.glnav.ddqPushFlags[myId] = true;
                }
            }
        },

        navigateToSeamlessInfo: function() {
            // targets the seamless login info
            window.location.href = 'https://eweb2.vfd2-testnet.de/meinvodafone/account/login/seamless/info';
        },


        /**
         * user logged in getter and setter
         * @return {bool} either true or false.
         * @return {object} Object containing user name and login date
         */
        getLoginDataVO: function() {
            if ('undefined' !== typeof vf.glnav.settings.loginDataVO && null !== vf.glnav.settings.loginDataVO) {
                return vf.glnav.settings.loginDataVO;
            } else {
                return false;
            }
        },
        /**
         * @param {object} loginDataVO - Object containing user name and login date
         * userFirstname
         * userLastname
         * lastLoginDateFormatted
         * lastLoginTimeFormatted
         *
         * * @return {object} loginDataVO
         */
        setLoginDataVO: function(loginDataVO) {
            vf.glnav.settings.loginDataVO = loginDataVO;
            if ('undefined' !== typeof vf.glnav.settings.loginDataVO && null !== vf.glnav.settings.loginDataVO) {
                $('.mod-glnav .item-myvf .myvf-network').css('display', 'none');
                $('.mod-glnav .item-myvf .indicator').css('display', 'block');
                $('.mod-glnav .myvf-flyout .h5').html('Du bist eingeloggt');
                $('.mod-glnav .myvf-flyout .text-body').html('Dein letztes Login:');
                $('.mod-glnav .myvf-flyout .login-date').css('display', 'block').html(loginDataVO.lastLoginDateFormatted);
                $('.mod-glnav .myvf-flyout .login-time').css('display', 'block').html('um ' + loginDataVO.lastLoginTimeFormatted);
                // poulate the user info
            } else {
                if (vf.loginState === vf.LOGIN_STATES_CONST.SEAMLESS_AVAILABE) {
                    $('.mod-glnav .item-myvf .myvf-network').css('display', 'block');
                    $('.mod-glnav .item-myvf .indicator').css('display', 'none');
                    $('.mod-glnav .item-myvf .seamless-logged-in').css('display', 'none');
                    $('.mod-glnav .myvf-flyout').removeClass('nav-display');
                } else if (vf.loginState === vf.LOGIN_STATES_CONST.SEAMLESS_LOGIN) {
                    $('.mod-glnav .item-myvf .seamless-logged-in').css('display', 'block');
                    $('.mod-glnav .item-myvf .myvf-network').css('display', 'none');
                    $('.mod-glnav .myvf-flyout .h5').html('AutoLogin aktiv');
                    $('.mod-glnav .myvf-flyout .text-body').html('Über das Mobilfunknetz automatisch erkannt – <a>mehr Infos</a>');
                    $('.mod-glnav .myvf-flyout .login-date').css('display', 'none');
                    $('.mod-glnav .myvf-flyout .login-time').css('display', 'none');
                    $('.mod-glnav .myvf-flyout .text-body a').on('click', vf.glnav.navigateToSeamlessInfo);
                } else if (vf.loginState === vf.LOGIN_STATES_CONST.STANDARD_LOGOUT) {
                    $('.mod-glnav .item-myvf .indicator').css('display', 'none');
                }

            }
            return vf.glnav.settings.loginDataVO;
        },

        /**
         * Reads login cookie
         * @return {object} cookieData - object containing  username  and login date
         */
        getLoginCookieData: function() {
            var cookieDataStr = $.cookie('MDDKeks');
            if (cookieDataStr) {
                var cookieData = JSON.parse(cookieDataStr);
                var formatedDateAndTime = this.getDateAndTimeFromTimestamp(parseInt(cookieData.lastLoginDate));
                cookieData.lastLoginDate = formatedDateAndTime.formatedDate;
                cookieData.lastLoginTime = formatedDateAndTime.formatedTime;
                var lt = /</g,
                    gt = />/g,
                    ap = /'/g,
                    ic = /"/g,
                    amp = /&/g;
                cookieData.userFirstname = cookieData.userFirstname.toString().replace(amp, '&amp;').replace(lt, '&lt;').replace(gt, '&gt;').replace(ap, '&#39;').replace(ic, '&#34;');
                cookieData.userLastname = cookieData.userLastname.toString().replace(amp, '&amp;').replace(lt, '&lt;').replace(gt, '&gt;').replace(ap, '&#39;').replace(ic, '&#34;');

                return cookieData;
            }
            return false;
        },

        /**
         * update login indicator
         */
        updateLoginIndicator: function() {
            var loginCookieData = vf.glnav.getLoginCookieData();
            if (loginCookieData) {
                vf.loginState = vf.LOGIN_STATES_CONST.STANDARD_LOGIN;
                vf.glnav.setLoginDataVO({
                    userFirstname: loginCookieData.userFirstname,
                    userLastname: loginCookieData.userLastname,
                    lastLoginDateFormatted: loginCookieData.lastLoginDate,
                    lastLoginTimeFormatted: loginCookieData.lastLoginTime
                });
            } else {
                if ('mobile' === $.cookie('vfct') && $.cookie('ecare.seamlessLoginStatus')) {
                    vf.loginState = vf.LOGIN_STATES_CONST.SEAMLESS_LOGIN;
                } else if ('mobile' === $.cookie('vfct') && !$.cookie('ecare.seamlessLoginStatus')) {
                    vf.loginState = vf.LOGIN_STATES_CONST.SEAMLESS_AVAILABE;
                } else {
                    vf.loginState = vf.LOGIN_STATES_CONST.STANDARD_LOGOUT;
                }
                vf.glnav.setLoginDataVO(null);
            }
        },

        /**
         * Utility function for above
         * @param {int} timestamp - Object containing user name and login date
         * @return {object} retrunObject - obejct containing formated date and time
         */
        getDateAndTimeFromTimestamp: function(timestamp) {
            var myDate = new Date(timestamp);
            var addZeroIfRequired = function(val) {
                return (10 > val ? ('0' + val.toString()) : val.toString());
            };
            var retrunObject = {};
            retrunObject.formatedDate = addZeroIfRequired(myDate.getDate()) + '.' + addZeroIfRequired(myDate.getMonth() + 1) + '.' + addZeroIfRequired(myDate.getFullYear());
            retrunObject.formatedTime = addZeroIfRequired(myDate.getHours()) + ':' + addZeroIfRequired(myDate.getMinutes()) + ':' + addZeroIfRequired(myDate.getSeconds());
            return retrunObject;
        },

        /**
         * @param {int} itemsCounter - number of items
         */
        setShoppingChartItemCounter: function(numberOfItems) {
            if (0 < numberOfItems) {
                $('.mod-glnav .counter').text(numberOfItems);
                $('.mod-glnav .counter').css('display', 'block');
            } else {
                $('.mod-glnav .counter').css('display', 'none');
            }
        },
    };
}(vf));
