$(document).ready(function() {
    'use strict';

    var codeChallenge = 'wv1m1vqYsov3wptWc3PPTXpyp69I47FQjdTUOvEx5oc';

    var codeVerifier = 'nfkpilFP2gaUoo0THBzqLudO1HQzGWGo~k_y5Wbv6zk';

    function getBaseUrl() {
        if ($.cookie('environment') && 'dev' === $.cookie('environment')) {
            return 'http://de-stubs.skytap-tss.vodafone.com/api/';
        } else if (
            $.cookie('environment') &&
            'devpreprod' === $.cookie('environment')
        ) {
            return 'http://de-stubs.skytap-tss.vodafone.com/api/';
        } else if (
            $.cookie('environment') &&
            'prod' === $.cookie('environment')
        ) {
            return 'https://eweb2.vfd2-testnet.de/';
        } else if (
            $.cookie('environment') &&
            'preprod' === $.cookie('environment')
        ) {
            return 'http://de-stubs.skytap-tss.vodafone.com/api/'; //not available on preprod
        } else if (
            $.cookie('environment') &&
            'opweb3' === $.cookie('environment')
        ) {
            return 'https://opweb3.vfd2-testnet.de/';
        } else if (
            $.cookie('environment') &&
            'eweb8' === $.cookie('environment')
        ) {
            return 'https://eweb8.vfd2-testnet.de/';
        } else if (
            $.cookie('environment') &&
            'local' === $.cookie('environment')
        ) {
            return 'https://vodafone.localhost/';
        } else {
            return '';
        }
    }

    function authorizeUser() {

        var Autorize_User =
            '{otdsUrl}mint/oidc/authorize?response_type=code&client_id=1kJPDY5g-5uNE-eius-aZjP-Ll8TChFOX9y4&scope=openid%20profile%20email%20account%20validate-token&redirect_uri={otdsUrl}meinvodafone/services/&code_challenge={code_challenge}&code_challenge_method=S256';

        var url = 'https://opweb3.vfd2-testnet.de/mint/oidc/authorize?response_type=code&client_id=1kJPDY5g-5uNE-eius-aZjP-Ll8TChFOX9y4&scope=openid%20profile%20email%20account%20validate-token&redirect_uri=https://simplicity.wf-de.vodafone.com/meinvodafone/services/&code_challenge=wv1m1vqYsov3wptWc3PPTXpyp69I47FQjdTUOvEx5oc&code_challenge_method=S256';

        return fetch(url, {
            redirect: 'follow',
            method: 'GET',
            xhrFields: {
                'withCredentials': 'true'
            }
        }).then(function(response) {

            if (response && response.url && 200 == response.status) {

                var locHeader = response.url;

                var codeValue = locHeader.slice(locHeader.indexOf('=') + 1);

                getAccessToken(codeValue);
            }
        }, function(error){

            console.log(error);
        });
    }

    $('#dxl-token-getter').click(function() {

        submitLogin();
    });
    $('#dxl-token-setter').click(function() {

        sessionStorage.setItem('ecare-LocalStore.data.dxlkey', $('#tokenViewer').val());
        ;
    });
    function getAccessToken(codeValue) {

        var Get_Access_Token =
            '{otdsUrl}mint/oidc/token?client_id=1kJPDY5g-5uNE-eius-aZjP-Ll8TChFOX9y4&client_secret=NLqVfyGW0arg5cpua1bwqX7Er3gpzDKVUafFun5uNOXGEomoViVjz135I4K0jDNPBY2xdOkWZoiwUeFanoaJcZ&grant_type=authorization_code&redirect_uri={otdsUrl}meinvodafone/services/&code={code_value}&code_verifier={code_verifier}';

        var url = 'https://opweb3.vfd2-testnet.de/mint/oidc/token?client_id=1kJPDY5g-5uNE-eius-aZjP-Ll8TChFOX9y4&client_secret=NLqVfyGW0arg5cpua1bwqX7Er3gpzDKVUafFun5uNOXGEomoViVjz135I4K0jDNPBY2xdOkWZoiwUeFanoaJcZ&grant_type=authorization_code&redirect_uri=https://simplicity.wf-de.vodafone.com/meinvodafone/services/&code={code_value}&code_verifier={code_verifier}'
            .replace('{code_verifier}', codeVerifier)
            .replace('{code_value}', codeValue);

        fetch(url, {
            method: 'POST',
            xhrFields: {
                'withCredentials': 'true'
            }
        }).then(function(response) {

            return response.json();
        }).then(function(data){

            if (data && data.token_type) {

                sessionStorage.setItem('ecare-LocalStore.data.dxlkey', data.token_type + ' ' + data.access_token);
                $('#tokenViewer').val(data.token_type + ' ' + data.access_token);
            }
        }, function(error) {
        });
    }


    function submitLogin(){

        var url = 'https://opweb3.vfd2-testnet.de/mint/rest/session/start';

        var body = {
            username: document.getElementsByName('dxl-login-name')[0].value,
            password: document.getElementsByName('dxl-login-password')[0].value,
            clientType: 'Portal'
        };

        fetch(url, {
            method: 'POST',
            body: JSON.stringify(body),
            redirect: 'manual',
            xhrFields: {
                'withCredentials': 'true'
            }
        }).then(function(response) {

            return response;
        }).then(function() {
        });
    }

    $('#dxl-authorize-setter').click(function() {

        authorizeUser();
    });

});
