(function () {

    var settings = {
        target: '.mod-token-field',
        tokenUrl: '//eweb2.vfd2-testnet.de/taf/fetchsendtoken'
    };

    var fileIndex = 0;

    var tokenField = function($input){

        $.ajax({
            url: settings.tokenUrl,
            dataType: 'json'
        })
        .done(function(data, textStatus, request){
            if (undefined === data.token){
                return;
            }
            $input.val(data.token);
        });
        // no error path -> if request fails, token stays empty

    };

    var init = function(){

        $(settings.target).each(function(){
            tokenField($(this));
        });

    };

    vf['token-field'] = {
        settings: settings,
        init: init
    };

}(vf));
